exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-feedbackform-js": () => import("./../../../src/pages/feedbackform.js" /* webpackChunkName: "component---src-pages-feedbackform-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lc-links-js": () => import("./../../../src/pages/lc-links.js" /* webpackChunkName: "component---src-pages-lc-links-js" */),
  "component---src-pages-lc-payment-js": () => import("./../../../src/pages/lc-payment.js" /* webpackChunkName: "component---src-pages-lc-payment-js" */),
  "component---src-pages-lc-thankpay-js": () => import("./../../../src/pages/lc-thankpay.js" /* webpackChunkName: "component---src-pages-lc-thankpay-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-methods-js": () => import("./../../../src/pages/methods.js" /* webpackChunkName: "component---src-pages-methods-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-psychologists-js": () => import("./../../../src/pages/psychologists.js" /* webpackChunkName: "component---src-pages-psychologists-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-test-review-js": () => import("./../../../src/pages/test-review.js" /* webpackChunkName: "component---src-pages-test-review-js" */),
  "component---src-pages-thank-js": () => import("./../../../src/pages/thank.js" /* webpackChunkName: "component---src-pages-thank-js" */),
  "component---src-pages-thankpay-js": () => import("./../../../src/pages/thankpay.js" /* webpackChunkName: "component---src-pages-thankpay-js" */),
  "component---src-templates-psychologist-js": () => import("./../../../src/templates/psychologist.js" /* webpackChunkName: "component---src-templates-psychologist-js" */)
}

