import React, { createContext, useContext, useReducer } from 'react'
import axios from 'axios'
const apiURL = process.env.GATSBY_STRAPI_API_URL

const DEFAULT_STATE = {
  jwt: null,
  user: {},
  loggedIn: false
}

const reducer = (state, action) => {
  switch(action.type){
    case 'LOGIN': 
      const { jwt = null, user = {} } = action.payload
      return {...state, jwt, user, loggedIn: true}
    case 'LOGOUT':
      return {...state, jwt: null, user: {}, loggedIn: false}
    default:
      return DEFAULT_STATE
  }
}

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  
  return(
    <AuthContext.Provider value={useReducer(reducer, DEFAULT_STATE)}>
      {children}
    </AuthContext.Provider>
  )
}

export const wrapRootElement = ({element}) => (
  <AuthProvider>
    {element}
  </AuthProvider>
)

const useAuth = () => {
  const [state, dispatcher] = useContext(AuthContext)
  const isAuthenticated = state.loggedIn && Object.keys(state.user).length

  const login = async (credentials) => new Promise(async(resolve, reject) => {
    try{
      const { data: payload } = await axios.post(`${apiURL}/api/auth/local?populate=profile&populate=profile.photo&populate=*`, credentials)
   
      console.log(payload)
      dispatcher({ type: 'LOGIN',  payload })
      resolve(payload)
      refresh(payload.jwt)
    }
    catch(e){
      console.log(e)
      reject(e)
    }
  })
  const logout = () => {
    dispatcher({ type: 'LOGOUT' })
    sessionStorage.removeItem('token')
  }
  const refresh = async (token) => new Promise(async(resolve, reject) => {
    try{
      console.log(token)
    const { data: data } = await axios.get(`${apiURL}/api/users/me?populate=profile.photo&populate=*`, 
   
      { headers: { Authorization: `Bearer ${token}`}}
      // { headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjkzOTExNjU0LCJleHAiOjE2OTY1MDM2NTR9.s6igYoCoav1i_UhmxJkqJPp8v4RxodCnN1-6rSRDVQU`}}
    )
    console.log(data)
    const payload = {jwt: token, user: data}
    console.log(payload)
  
    dispatcher({ type: 'LOGIN',  payload })
    resolve(payload)
  }
  catch(e){
    console.log(e)
    reject(e)
  }
  })

  return { state, isAuthenticated, login, logout, refresh }
}

export default useAuth